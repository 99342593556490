import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";


cytoscape.use(dagre);

function renderGraph(nodes, edges, noAutolock = false, storyId = undefined, onlyLinks = false) {
    const cy = window.cy = cytoscape({
        container: document.getElementById("cy"),

        minZoom: 0.1,
        maxZoom: 3,

        layout: {
            name: 'dagre',
            spacingFactor: onlyLinks ? 0.8 : 1.1,
            avoidOverlap: true,
        },

        style: [
            {
                selector: 'node',
                style: {
                    content: 'data(label)',
                    shape: 'rectangle',
                    'font-family':
                        'Open Sans,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
                    'line-height': 1.4,
                    'text-wrap': 'wrap',
                    'text-justification': 'left',
                    'text-valign': 'center',
                    'text-halign': 'center',
                    'background-color': 'white',
                    'border-width': '1px',
                    'border-style': 'solid',
                    'border-color': '#102a43',
                    'border-opacity': 0.5,
                    width: 'data(width)',
                    height: 'data(height)',
                    color: '#102A43',
                    'target-endpoint': 'outside-to-line-or-label',
                    'source-endpoint': 'outside-to-line-or-label',
                },
            },
            {
                selector: '.error',
                style: {
                    'border-color': 'red',
                    'border-width': '3px',
                },
            },
            {
                selector: '.ChatNodeStoryLink',
                style: {
                    'border-width': '2px',
                    'border-style': 'dashed',
                    'border-color': '#81cf4a',
                    'border-opacity': 1,
                },
            },
            {
                selector: '.DecisionNode',
                style: {
                    'background-color': '#E6F0FC',
                    'border-width': '1px',
                    'border-style': 'solid',
                    'border-color': '#E6F0FC',
                    'border-opacity': 0.5,
                },
            },
            {
                selector: '.MediaNode',
                style: {
                    'background-color': '#F1F3F5',
                    'border-color': '#C8D6E2',
                    'border-width': '1px',
                    'border-opacity': 0.5,
                },
            },
            {
                selector: '.StoryStartNode',
                style: {
                    'background-color': '#243B53',
                    'border-width': '1px',
                    'border-style': 'solid',
                    'border-color': '#243B53',
                    'border-opacity': 0.5,
                    color: '#FFFFFF',
                    'font-size': '18',
                },
            },
            {
                selector: '.ChatNodeMedia-image',
                style: {
                    content: '',
                    'background-image': 'data(url)',
                    'background-fit': 'cover cover',
                    'background-image-opacity': 0.75,
                    width: '200',
                    height: '200',
                },
            },

            {
                selector: '.Path',
                style: {
                    'background-color': '#1767d1',
                    color: 'white',
                },
            },

            {
                selector: 'edge',
                style: {
                    'curve-style': 'bezier',
                    width: 1,
                    'target-arrow-shape': 'triangle',
                    'line-color': '#A7BACD',
                    'target-arrow-color': '#A7BACD',
                },
            },
            {
                selector: '.BranchEdge',
                style: {
                    width: 4,
                },
            },
            {
                selector: '.StoryStartEdge',
                style: {
                    'line-style': 'dashed',
                    width: 3,
                    'line-color': '#233A4E',
                    'target-arrow-color': '#233A4E',
                },
            },
            {
                selector: '.StoryLinkEdge',
                style: {
                    'line-style': 'dashed',
                    'line-color': '#81cf4a',
                    width: 4,
                },
            },
            {
                selector: '.SubstoryEdge',
                style: {
                    'line-style': 'dashed',
                    'line-color': '#81cf4a',
                    width: 4,
                },
            },
            {
                selector: '.selectedNode',
                style: {
                    'background-color': '#0099cc',
                    'border-width': '3px',
                    'border-style': 'solid',
                    'border-color': '#ccf2ff',
                    color: '#FFFFFF',
                },
            },
            {
                selector: '.StoryNodeGroup',
                style: {
                    'border-width': '2px',
                    'border-style': 'dashed',
                    'border-opacity': 0.3,
                    'background-color': '#fafafb',
                },
            },
            {
                selector: '.StoryNodeGroup:selected',
                style: {
                    'background-color': '#CED4DA',
                },
            },
        ],

        elements: {
            nodes: nodes,
            edges: edges
        }
    })


    if (onlyLinks) {
        const isNodeVisibleInStoryLinkGraph = (n) => n.classes().includes('ChatNodeSubstory') || n.classes().includes('ChatNodeStoryLink') || n.classes().includes('StoryStartNode')

        let newEdges = []
        cy.nodes(function (n) {
            if (!isNodeVisibleInStoryLinkGraph(n)) {
                n.hide()
            } else {
                let predecessors = n.predecessors('node').filter((p) => {
                    if (isNodeVisibleInStoryLinkGraph(p)) {
                        return true
                    }

                })

                if (predecessors.length) {
                    newEdges.push(        {
                        group: 'edges',
                        "data": {
                            "source": predecessors[0].id(),
                            "target": n.id()
                        },
                        "classes": "Edge"
                    })
                }
            }
        })
        cy.add(newEdges)
    }


    let rootElement = cy.$id(storyId)

    if (!rootElement.length) {
        rootElement = cy.nodes('.' + storyId)
    }
    cy.fit(rootElement)
    cy.zoom(0.8)
    cy.center(rootElement)

    // cy.autolock(true)
    // cy.autolock(!noAutolock)

}


const urlParams = new URLSearchParams(window.location.search);
const dataUrl = urlParams.get('data')
const noAutolock = urlParams.get('autolock') === 'False'
const onlyLinks = urlParams.get('onlyLinks') === 'true'
let storyId = dataUrl.substring(dataUrl.lastIndexOf('/') + 1)
storyId = storyId.substring(0, storyId.lastIndexOf('.json') + 1)
storyId = storyId.substring(0, storyId.lastIndexOf('-'))

if (dataUrl) {
    fetch(dataUrl)
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            throw new Error('Something went wrong');
        })
        .then(data => {
            try {
                renderGraph(data.nodes, data.edges, noAutolock, storyId, onlyLinks)

            } catch (e) {
                console.error(e)
            }
        })
        .catch(er => {
            console.error(er)
            document.getElementById("data-missing").style.display = 'block';
        })
} else {
    document.getElementById("data-missing").style.display = 'block';
}

document.getElementById('export-graph').onclick = function(element) {
    element.preventDefault();

    let link = document.createElement('a');
    link.download = 'aiaibot-story-graph.png';
    let blob = cy.png({'output': 'blob', 'full': true});

    let reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onload = function() {
        link.href = reader.result;
        link.click();
    };

};
